import { jsPDF } from "jspdf";
import 'jspdf-autotable';

export const generatePdf = (cartItems, clearCartCallback, clientName = "Nombre del Cliente", contactPerson = "", clientData = "", notes = "") => {
  const doc = new jsPDF({
    orientation: "portrait",
    unit: "mm",
    format: "a3"
  });

  const date = new Date();
  const formattedDate = date.toLocaleString('es-ES');

  doc.setFontSize(10);
  doc.setFont("courier");
  doc.text(`Fecha: ${formattedDate}`, 10, 20);
  doc.text(`Cliente: ${clientName}`, 10, 30);
  doc.text(`Persona de Contacto: ${contactPerson}`, 10, 40);
  doc.text(`Datos adicionales: ${clientData}`, 10, 50);

  const tableColumn = ["Producto", "Cantidad", "Precio Unit.", "Extras", "Subtotal"];
  const tableRows = [];

  cartItems.forEach(item => {
    let productName;
    let extrasDescription = "";
    let totalItemPrice;

    if (item.productIdWithExtras.startsWith("menu:")) {
      productName = `Menú Diario: Primero - ${item.primeroSeleccionado}, Segundo - ${item.segundoSeleccionado}, Postre - ${item.postreSeleccionado}`;
      totalItemPrice = item.precioTotal * item.cantidad;
    } else {
      productName = `${item.familia.nombre} - ${item.nombre}`;
      extrasDescription = item.extras.map(extra => `${extra.nombre} (+${extra.precio.toFixed(2)}€)`).join(", ");
      const extrasTotalPrice = item.extras.reduce((total, extra) => total + extra.precio, 0);
      totalItemPrice = (item.precio + extrasTotalPrice) * item.cantidad;
    }

    const itemData = [
      productName,
      item.cantidad,
      `${item.precio.toFixed(2)}€`,
      extrasDescription || "Sin extras",
      `${totalItemPrice.toFixed(2)}€`
    ];

    tableRows.push(itemData);
  });

  doc.autoTable({
    head: [tableColumn],
    body: tableRows,
    startY: 60,
    theme: 'plain',
    styles: { font: "courier", fontSize: 9, cellPadding: 2, overflow: 'linebreak' },
    headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], fontStyle: 'normal', lineWidth: 0.1 },
    columnStyles: {
      0: { cellWidth: 100 },
      1: { cellWidth: 30 },
      2: { cellWidth: 25 },
      3: { cellWidth: 50 },
      4: { cellWidth: 25 }
    },
    margin: { horizontal: 10 },
    tableLineWidth: 0.1
  });

  const totalPrice = cartItems.reduce((total, item) => {
    const extrasTotalPrice = item.extras ? item.extras.reduce((sum, extra) => sum + extra.precio, 0) : 0;
    return total + (item.precioTotal * item.cantidad + extrasTotalPrice * item.cantidad);
  }, 0);

  doc.setFontSize(11);
  doc.text(`Total a Pagar: ${totalPrice.toFixed(2)}€`, 150, doc.lastAutoTable.finalY + 10, { align: 'center' });

  if (notes) {
    doc.setFontSize(10);
    doc.text(`Notas: ${notes}`, 10, doc.lastAutoTable.finalY + 20);
  }

  const dateString = date.toISOString().slice(0, 19).replace(/:/g, '-').replace('T', '_');
  const filename = `comanda_${dateString}.pdf`;

  const pdfBlob = doc.output('blob');
  window.open(URL.createObjectURL(pdfBlob));

  if (clearCartCallback) clearCartCallback();
}

export default generatePdf;
